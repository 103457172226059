.finacial-report-container {
    padding: 8px 16px 16px !important;
    height: inherit;
    display: flex;
    background: #fafbff;
  }
  .report-preview-heading {
    border-bottom: 0.5px solid #e6e8f0;
    height: 63.5px;
    display: flex;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
  
    color: #4a4a4a;
    margin-bottom: 19.5px;
  }
  
  .saved-cards-heading {
    font-size: 20px;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #262626;
  }
  
  .saved-expandable-group-container {
    height: 34px;
    width: 100%;
    padding: 0px 12px;
    border-bottom: 1px solid #e6e8f0;
    display: flex;
    align-items: center;
    cursor: pointer;
    background-color: #fff;
  
    .saved-expandable-group-title {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
  
      color: #474d66;
      flex: 1 1 auto;
    }
  }
  
  .saved-draft-item-container {
    height: 32px;
    display: flex;
    align-items: center;
    background-color: #fafbff;
    border-bottom: 1px solid #e6e8f0;
    padding: 0px 37.5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
  
    svg {
      fill: #c1c4d6;
    }
  
    .saved-draft-item-menu {
      display: none;
      svg {
        fill: #75cf7c;
        width: 30px;
        height: 20px;
      }
    }
  
    &:hover {
      background: #eef8f4;
      color: #3d8e44;
      svg {
        fill: #3d8e44;
      }
  
      .saved-draft-item-menu {
        display: block;
      }
    }
  }

//   new
.note-template-addnew{
    display: flex;
    justify-content: space-between;
}
.note-template-addnew button{
    background-color: #ffffff;
    border: 1px solid #D8DAE5;
    border-radius: 4px;
    color:#696F8C;;
}
.component-btn-design button{
    background-color: #ffffff;
    border: 1px solid #D8DAE5;
    border-radius: 4px;
    color:#696F8C;;
}
.components-style .component-dropdown-popup{
    width: 100%;
}
.noteTemplateBreadrumb{
    display: flex;
    gap: 8px;
    padding-left: 38px;
    padding-top: 8px;
    background-color: #fafbff ;
}
.noteTemplateBreadrumb p{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
}
.item-type-name{
    display: flex;
    gap: 8px;
    align-items: center;
    color: #707081;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 11px;
}
.item-type-name span{
    background-color: #EDEFF5;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
.subitem-name{
    background-color: #ffffff;
    font-size: 14px;
    line-height: 16px;
    padding: 14px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.subitem-name:hover{
    background-color: #F5FBF8;
    color: #208460;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    cursor: pointer;
}
.new-note-template{
    margin-bottom: 15px;
    display: inline-block;
    width: 100%;
}
.components-style .ant-select-item{
    text-transform: capitalize;
}
.ant-select-selection-item{
    text-transform: capitalize;
}
.note-template-title{
    font-weight: 500;
    line-height: 24px;
    font-size: 14px;
    padding-bottom: 9px;
}
.note-edit-template{
    padding: 30px 0;
}
.note-type{
    background-color: #D6E0FF;
    color: #2952CC;
    padding: 2px 6px;
    border-radius: 5px;
    font-size: 11px;
    font-weight: 600;
    text-transform: uppercase;
}
.tolltip-type-select .ant-tooltip-inner{
    padding: 10px;
    background-color:rgb(255 255 255 / 75%) !important;
    color: #000000 !important;
}
.note-edit-template .editor-container .editor-content{
    min-height: 100px !important;
    height: 250px;
    overflow: auto;
}
.note-edit-template .editor-container {
    margin-top: 10px;
}
.footer-submit-section button{
    padding: 15px 50px;
    border-radius: 20px;
    display: flex;
    gap:"20px";
    align-items: center;
}
.finacial-report-container.note-template-height-setter .left-part {
  overflow-y: auto;
  height: calc(100% - 0px);
  min-height: 150px;
}
.finacial-report-container.note-template-height-setter .r-box-1550{
  overflow-y: auto;
  height: calc(100% - 0px);
  min-height: 150px;
}
.main-container .finacial-report-container.note-template-height-setter{
  padding-top:40px !important;
  margin-top:-32px;
}
.finacial-report-container.note-template-height-setter .ant-card.ant-card-large{
  height: calc(100vh - 40px)!important;
  overflow-y: auto;
}
@primary-color: #4BB354;