.main-container {
  display: flex;
  height: 100%;

  .main-container-content {
    // height: calc(100% - 77px);
    overflow-y: auto;
    flex: 1 1;
  }

  .finacial-report-container {
    padding: 16px;
    height: 100%;
    display: flex;
    background: #fafbff;
  }
}

@primary-color: #4BB354;