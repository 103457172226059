.ant-modal-footer {
  border: none;
}

.ant-modal-header {
  border: none;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  color: #101840;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ant-modal-content {
  border-radius: 8px;
}
