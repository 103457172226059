.title {
  
  .right-part {
    /*
    position: absolute;
    right: 0;
    */
    text-align: right;
  }

  .tips {    
    font-weight:bold;
    font-size:16px;    
    position: relative;       
  }  

}
@primary-color: #4BB354;