.ant-popover-title {
  min-width: 177px;
  padding-top: 12px !important;
  min-height: 32px;
  display: flex;
  align-items: center;
  padding: 5px 16px 4px;
  font-weight: 500;
  border-bottom: none;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  color: #696f8c;
}

.ant-popover-inner-content {
  padding: 0px 0px !important;
}

.ant-popover {
  z-index: 1000;
}
