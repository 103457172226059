.header-container {
  height: 77px;
  width: 100%;
  background: #ffffff;
  min-height: 77px;
  border-bottom: 1px solid #d8dae5;
  display: flex;
  align-items: center;
  padding: 0px 47px;

  .header-user-name {
    color: #4a4a4a;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-left: 10px;
  }
}

@primary-color: #4BB354;