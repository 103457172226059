.saved-delete-report-text-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #696f8c;
}

.saved-delete-report-text-subtitle {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #696f8c;
}

@primary-color: #4BB354;