.component-button {
  padding-left: 0.5em;
  padding-right: 0.3em;
  padding: 0px 16px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}
.component-button > .anticon + span {
  margin-left: 4px;
}

@primary-color: #4BB354;