.steps-list-parent-container {
  position: relative;
  top: -13px;
  width: 100%;

  .steps-list-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .steps-step-number {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: #edeff2;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.2);
  }

  .steps-step-title {
    width: 80px;
    text-align: center;
    margin-left: -20px;
    margin-top: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    min-height: 32px;
  }
}

.report-steps-card {
  .ant-card-body {
    display: flex;
    align-items: center;
  }
}

.create-or-edit-report-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  .ant-card-body {
    width: 100%;
    display: flex;
    // align-items: center;
  }
}

.upload-data-container {
  border: 1px solid #fff;
  border-radius: 4px;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #fff;
  flex: 1 1;
  background-color: #4bb354;
  cursor: pointer;

  svg {
    fill: #fff;
  }
}

.upload-data-container.disabled {
  border: 1px solid #d8dae5;
  cursor: not-allowed;

  color: #c1c4d6;
  background-color: #fff;
}

.file-settings-container {
  margin-left: 17px;
  width: 80px;
  height: 32px;
  display: flex;
  min-width: 80px;
  align-items: center;
  justify-content: center;
  color: #4bb354;
  cursor: pointer;
}

.file-settings-container.selected {
  height: 32px;
  background: #fafbff;
  border-radius: 4px;
  border: 2px solid #dcf2ea;
  text-align: center;
  color: #696f8c;
}

.expand-collpase-container {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #4bb354;
  cursor: pointer;
}

.report-preview-heading {
  border-bottom: 0.5px solid #e6e8f0;
  height: 63.5px;
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;

  color: #4a4a4a;
  margin-bottom: 19.5px;
}

.statement-of-equity-add-column {
  min-width: 32px;
  height: 40px;
  background: #f5fbf8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  padding: 0px;
  color: #4bb354;
  margin-left: 2px;
  cursor: pointer;
}
.svg-pos {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 20px;
}
.statement-of-equity-delete-column {
  min-width: 32px;
  height: 32px;
  background: #f5fbf8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  padding: 0px;
  color: #4bb354;
  margin-left: 2px;
  cursor: pointer;
}

.step-four-current-period-date-container {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

  color: #4a4a4a;
  margin-bottom: 8px;
}

.ant-picker {
  border: 1px solid #d8dae5;
  border-radius: 4px;
}

.step-four-period-title-container {
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;

  color: #4a4a4a;
  margin-top: 8px;
  margin-bottom: 4px;
}

.expand-collpase-container {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #4bb354;
  cursor: pointer;
}

.config-details {
  .title-block {
    margin-top: 25px;
    b {
      font-size: 16px;
    }

    & > .title {
    }
    & > .input {
      margin-top: 6px;
    }
  }
  .rich-text-container {
  }
  .report-template-container {
    margin-top: 0px;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.preview-sheet-back-container {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #4bb354;
  cursor: pointer;
}

.preview-sheet-title-container {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #8f95b2;
  border: none !important;
}

.preview-sheet-data-table-header {
  display: flex;
  align-items: center;
  height: 48px;
  background: #fafbff;

  .preview-sheet-header-title {
    width: 50%;
  }

  .preview-sheet-header-period {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    width: 25%;
    text-align: right;
  }
}

.preview-sheet-data-item-container {
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1.5px solid #e6e8f0;

  .preview-sheet-data-header {
    flex: 1 1 auto;
    font-size: 14px;
    line-height: 24px;

    /* align-items: center; */
    color: #474d66;
    font-weight: 700;
  }

  .preview-sheet-data-title {
    width: 50%;
    color: #474d66;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .preview-sheet-data-period {
    width: 25%;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  .ellipsis-text {
    display: inline-block;
    width: 100%;
    max-width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .preview-sheet-data-previous-period {
    width: 25%;
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
}
.editor-container .editor-content {
  overflow-y: auto;
  height: calc(100vh - 456px);
  min-height: 150px;
}
.finacial-report-container .left-part {
  //overflow-y: auto;
  height: calc(100vh - 220px);
  min-height: 150px;
}

.step-five-manage-formula-container {
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #696f8c;
  font-weight: 400;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.preview-btn {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  margin-right: 10px;
  text-align: center;
  color: #696f8c;
  background: transparent;
  padding: 6px 16px;
  border: solid 1px #d8dae5;
  border-radius: 4px;
  cursor: pointer;
}

@primary-color: #4BB354;