.r-box-1550 {
  margin-left: 12px;
  width: 60%;
  //   float: right;
  //   margin-right: -14px;
}

.r-box-1550-collapsed {
  width: 34px;
  min-width: 34px;
  background-color: #fff;
  margin-left: 12px;
  padding: 16px 11px;
}
.r-boxed-1550-enlarged{
  width: 100%;
  .btn-confirm-1648 {
    position: fixed;
    z-index: 10;
    padding: 0.5em;
    bottom: 24px;
    margin-left: 20px;
    background-color: #fff;
  }
}
@primary-color: #4BB354;