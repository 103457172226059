.note-templates-title-container {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #474d66;
  margin-left: 12px;
  padding: 0px;
}

.note-template-subtitle {
  margin-top: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474d66;
  margin-left: 28px;
}

.notes-click-add-container {
  margin-left: 28px;
  font-size: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #474d66;
  margin-top: 2px;
}

.notes-sub-note-container {
  background: #ffffff;
  border: 0.942735px solid rgba(217, 217, 217, 0.9);
  border-radius: 18.8547px;
  width: fit-content;
  padding: 6px 10px;
  margin-top: 5px;
  cursor: pointer;
}

.notes-preview-notes-container {
  color: #101840;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
}

.notes-preview-version-container {
  background: #fafbff;
  border: 1px dashed #c1c4d6;
  border-radius: 6px;
  padding: 10px;
}

.notes-preview-data-container {
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: auto !important;
    border-radius: 5px;
    display: block;
    max-width: -moz-fit-content;
    // max-width: fit-content;
    overflow-x: auto;
    white-space: nowrap;
    min-width: unset !important;
  }

  td,
  th {
    border: 1px solid #aaa;
    // box-sizing: border-box;
    // min-width: 200px;
    padding: 9px 22px;
    position: relative;
    // vertical-align: top;
    text-align: left;
  }
}

.notes-preview-disclaimer-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707081;
}

.notes-preview-body-template {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #c1c4d6;
  margin-top: 8px;
  margin-bottom: 2px;
}

.notes-preview-note-heading {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #474d66;
  margin-bottom: 10px;
  margin-top: 15px;
}

@primary-color: #4BB354;