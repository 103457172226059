.group-radio {
  // display: flex;
  .tabs {
    flex: 1;
    height: 80px;
    overflow-y: auto;
    &.collpased {
      overflow-x: auto;
      white-space: nowrap;
      height: auto;
      overflow-y: auto;
    }
    :global {
      .ant-radio-group {
        .ant-radio-button-wrapper {
          padding: 0 12px;
          margin-top: 6px;
          border: 0px;

          font-weight: 400;
          color: #999999;

          /*max-width: 140px;*/
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &::before {
            width: 0px;
          }

          &.ant-radio-button-wrapper-checked {
            .ant-radio-button-checked {
              box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
              border-radius: 16px;
              border: 1px solid #ececec;
            }
            span {
              font-size: 14px;
              // font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
  .operate {
    // width: 60px;
    padding-top: 40px;
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    color: #4bb354;

    .collapsed-btn {
      margin-left: 8px;
    }
    span {
      span {
        cursor: pointer;
      }
    }

    .ordered-list {
      margin-left: 8px;
    }
  }
}

.controller-buttons {
}
.controller-buttons .button-item {
  margin-right: 12px;
  color: #696f8c;
  border-color: #d8dae5;

  border-radius: 4px;
  box-shadow: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.controller-buttons .ant-btn-primary {
  color: #4bb354;
  background-color: transparent;
  border-color: #4bb354;
  text-shadow: none;
}

/*拖拽外框*/
.drag-box-1818 {
  /*background-color:#ccc;*/
  padding: 0em;
}
.drag-box-1818 .dropp-able-box {
  /*border:1px solid #333;*/
  padding: 0em;
  overflow: auto;
  max-height: 400px;
}
.drag-box-1818 .box-1842 {
  display: block;
  background-color: #fff;
  margin-bottom: 2px;
  margin-right: 0px;
  padding: 0.2em;
  float: left;
  cursor: pointer !important;
  color: #999;
  max-width: 9em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #fff;
  height: 36px;
  line-height: 30px;
  padding-left: 12px;
  padding-right: 12px;
  border-radius: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 4px;
}
.drag-box-1818 .box-1842:hover {
  color: #666;
  border: 1px solid #ececec;
}
.drag-box-1818 .box-1842:active {
  color: #666;
  border: 1px solid #ccc;
}
.drag-box-1818 .box-cur {
  border: 1px solid #ccc;
  color: #333;
}
.drag-box-1818 .box-cur:hover {
  border: 1px solid #ccc;
}
.sort-1818 .box-1842 {
  float: none;
  border: 1px dashed #ccc;
  /*border-radius: 0px;*/
  cursor: move !important;
  max-width: 100%;
  margin-bottom: 6px;
}

.sort-not-1818 .box-1842 {
  float: none;
  border: 1px dashed #ccc;
  /*border-radius: 0px;*/
  cursor: unset !important;
  max-width: 100%;
  margin-bottom: 6px;
}
.sort-1818 .box-cur {
  border: 1px dashed #4bb354;
  color: #4bb354;
  font-weight: bold;
}

.sort-not-1818 .box-cur {
  border: 1px dashed #4bb354;
  color: #4bb354;
  font-weight: bold;
}
.move-tips {
  /* position: absolute;
  margin-top: -23px; */
  color: green;
  opacity: 0.5;
  flex: 1 1 auto;
}

.note-template-popover .ant-popover {
  z-index: 1000 !important;
}

.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;

  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #474d66;
  margin-left: 12px;
  padding: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.saved-draft-item-menu {
  display: none;
  svg {
    // fill: #75cf7c;
    // width: 30px;
    // height: 20px;
  }
}

@primary-color: #4BB354;