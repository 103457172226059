.saved-previous-report-item-conatiner {
  height: 40px;
  padding: 0px 16px;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #474d66;
}
.ellipsis-text{
  display: inline-block;
  width: 420px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 24px;
  margin-bottom: -7px;
}
@primary-color: #4BB354;