.mapping-loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #707081;
  flex-direction: column;
}

@primary-color: #4BB354;