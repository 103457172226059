.component-dropdown-popup {
  background: #ffffff;
  border: 1px solid #d8dae5;
  border-radius: 4px;
  min-width: 120px;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  color: #474d66;
  font-size: 13px;
  cursor: pointer;
}

@primary-color: #4BB354;