.left-part {
  overflow-y: scroll;
  //   position: relative;
  width: 40%;
  .btn-confirm-1648 {
    position: fixed;
    z-index: 10;
    padding: 0.5em;
    bottom: 24px;
    margin-left: 20px;
    background-color: #fff;
  }
}

.left-part-expanded {
  overflow-y: scroll;
  //   position: relative;
  width: 100%;
  .btn-confirm-1648 {
    position: fixed;
    z-index: 10;
    padding: 0.5em;
    bottom: 24px;
    margin-left: 20px;
    background-color: #fff;
  }
}

.left-part-collapsed{
  width: 40px;
  min-width: 40px;
  background-color: #fff;
  margin-left: 12px;
  padding: 16px 11px;
}
@primary-color: #4BB354;