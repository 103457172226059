.component-popup-search .ant-input {
  border: none !important;
  background-color: #fafbff !important;
}
.component-popup-search .ant-input-search-button {
  border: none !important;
  background-color: #fafbff !important;
}

.component-popup-search {
  border-bottom: 1px solid #d8dae5;
  background-color: #fafbff;
}
