.login-container {
  height: 100%;
  width: 100%;
  background-color: #bceae4;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-card-container {
    width: 533px;

    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 23px 0px rgb(184 195 209 / 7%);
    padding: 35px 50px;

    .login-sign-in-text {
      font-size: 22px;
      font-weight: bold;

      color: #32aa4b;
      margin-top: 30px;
    }

    .login-sign-in-welcome {
      color: #aeaeae;
      font-size: 14px;
      padding-bottom: 40px;
      padding-top: 4px;
    }

    .ant-input-affix-wrapper {
      color: rgba(0, 0, 0, 0.25);
      font-size: 14px;
      margin-right: 12px !important;
    }
  }
}

@primary-color: #4BB354;