.save-reports-heading-container {
    margin-bottom: 4px;
    margin-top: 12px;
  
    .save-reports-heading-title {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #4a4a4a;
    }
  }
  
@primary-color: #4BB354;