.component-sub-title-container {
  background: #fafbff;
  border-radius: 4px;
  height: 42px;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;

  color: #474d66;
}

@primary-color: #4BB354;