.component-item-container {
  height: 32px;
  border: 1px solid #d8dae5;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 12px;
  width: fit-content;
  margin-bottom: 16px;
  cursor: pointer;
}

.component-item-container.selected {
  border: 1px solid #bcdcbf;

  background-color: #f5fbf8;
  color: #4bb354;
}

@primary-color: #4BB354;