.make-adjustment-journal-date-container {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4a4a4a;
  margin-bottom: 8px;
}
.new-equation-width {
  width: 100%;
  max-width: calc(100% / 3 - 10px);
}
.modal-component-height .ant-select .ant-select-selector {
  background-color: #fff !important;
}

@primary-color: #4BB354;