.rich-text {
  .rich-text-textarea {
    margin-top: 15px;
  }
  .preview-button {
    margin-top: 24px;
  }
}

.button-separator {
  border: 1px solid #e6e8f0;
  height: 16px;
  margin: 0px 10px;
}

.ant-pagination {
  display: none;
}

.dx-htmleditor-submit-element {
  display: none;
}

.dx-toolbar-items-container {
  display: none;
}

@primary-color: #4BB354;