.saved-discard-report-head-container {
  padding-bottom: 10;
}

.saved-discard-report-text-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #101840;
}

@primary-color: #4BB354;