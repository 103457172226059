.popup-item-container {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0px 16px;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  svg {
    fill: #8f95b2;
  }

  &:hover {
    background: #eef8f4;
    color: #4bb354;
    svg {
      fill: #4bb354;
    }
  }
}

.add-new-popup-item-container {
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #4bb354;
  padding: 0px 10px;
  cursor: pointer;
}

@primary-color: #4BB354;