.make-adjustment-journal-date-container {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #4a4a4a;
    margin-bottom: 8px;
  }
  .equation-btn{
    border: solid 1px #d8dae5;
    display: inline-block;
    width: 100%;
    align-items: center;
    padding: 5px 16px;
    border-radius: 4px;
    background-color: #fff;
    text-align: center;
  }
  .item-modal-equation{
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    background-color: #F7F7F7;
    padding: 15px;
    margin-bottom: 8px;
    border-radius: 7px;
  }
  .item-modal-equation:hover{
    color: #6fbf73;
  }
  .modal-equation-height{
    height: 308px;
    overflow: auto;
  }
  .equation-btn-add-new{
    width: 100%;
    max-width: 200px;
    color: #fff;
    border-radius: 7px;
    background-color: #4BB354;
    display: inline-block;
    align-items: center;
    padding: 5px 16px;
    text-align: center;
    position: absolute;
    top: 11px;
    right: 50px;
  }
@primary-color: #4BB354;