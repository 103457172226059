.report-drive-expandable {
  // height: 44px;
  width: 100%;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  color: #8f95b2;
  margin-bottom: 14px;
  background-color: transparent;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-transform: capitalize;
}
.report-drive-expandable:hover .saved-expandable-group-title,
.report-drive-expandable:hover svg {
  color: #208460;
  fill: #208460;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.report-drive-draftitem {
  padding: 14px 10px;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.report-drive-title-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.report-drive-draftitem:hover {
  color: #208460;
  background: #f5fbf8;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.upload-draft-item {
  display: none;
}
.upload-draft-item svg {
  fill: #208460;
}
.report-drive-draftitem:hover .upload-draft-item {
  display: block;
  cursor: pointer;
}
.uploaded-file-document {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  margin-left: 10px;
  padding-left: 10px;
  margin-top: 20px;
  text-transform: capitalize;
  position: relative;
}
.uploaded-file-document:after {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 19px;
  width: 1px;
  background-color: #000000;
  border-radius: 2px;
}
.report-drive-draftitem:hover .uploaded-file-document {
  font-weight: 700;
  cursor: pointer;
  max-width: max-content;
}
.report-drive-draftitem:hover .uploaded-file-document:after {
  width: 2px;
  background-color: #208460;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.before-upload-view span {
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #707081;
}
.before-upload-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
  height: 100%;
}
.after-upload-view .report-title {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #4a4a4a;
  margin-bottom: 7px;
  margin-top: 27px;
}
.after-upload-view {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4a4a4a;
}
.report-download-btn,
.report-delete-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #707081;
  display: flex;
  align-self: start;
  align-items: center;
  gap: 8px;
  margin-top: 27px;
  cursor: pointer;
}
.report-download-btn svg {
  fill: #8f95b2;
}
.report-delete-btn svg {
  fill: #d14343;
}
.finacial-report-container.report-drive-height-setter {
  height: calc(100vh - 110px);
}
.finacial-report-container.report-drive-height-setter .left-part {
  height: calc(100vh - 122px);
}

@primary-color: #4BB354;