.start-end-date-picker-container {
  display: flex;
  align-items: center;

  .start-end-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #8f95b2;
    margin-bottom: 2px;
  }
}

@primary-color: #4BB354;