@import "~antd/dist/antd.less";

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

.primary {
  color: @primary-color !important;
}

.reporter-table-container {
  margin-top: 24px;
}
.left-border {
  padding-left: 29px;
  border-left: 3px solid #4bb354;
}
.title-box-1710 {
}
.title-box-1710 button.red {
  color: #ff0000;
}

@primary-color: #4BB354;